import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import type { Image } from 'types/image'
import arrowIcon from 'assets/icons/arrow-right.svg'

import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'

export type Slide = Image & {
  slideInnerLabel: string
  label: string
  link: string
  additionalLabel: string
}

type Props = {
  slides: Slide[]
  slidesPerView: number | 'auto'
}

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-inline: auto;

  ${({ theme }) => theme.media.lg.min} {
    max-width: unset;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SlideWrapper = styled.div`
  position: relative;
  padding-bottom: 85px;

  /* width: 380px; */
  height: 360px;

  [data-rmiz-wrap='visible'] {
    /* width: 100%; */
    height: 100%;

    & > div {
      /* width: 100%; */
      height: 100%;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    transform: translate3d(1px);
  }
`

const SlideInnerLabel = styled(Text)`
  position: absolute;
  top: calc(50% - 40px);
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 4px;
`

const SlideInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 85px;
  box-sizing: border-box;
  padding-top: 1.5rem;
  opacity: 0.7;
`

// const ControlButtons = styled.div`
//   position: absolute;
//   top: calc(50% - 40px);
//   left: 50%;
//   transform: translate(-50%, -50%);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 90%;
//   z-index: 1;

//   div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   ${({ theme }) => theme.media.sm.min} {
//     width: 127.5%;
//   }

//   ${({ theme }) => theme.media.lg.min} {
//     width: 97.5%;
//   }

//   ${({ theme }) => theme.media.xxl.min} {
//     width: 112.5%;
//   }
// `

const ControlButton = styled.div<{ isHomepage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 40px);
  transform: translateY(-50%);
  left: 7.5%;
  z-index: 1;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid #dedede;

  &:hover {
    background-color: #dedede;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :not(:last-child) {
    & > div {
      transform: rotate(180deg);
    }
  }

  :last-child {
    left: unset;
    right: 7.5%;
    transform: translateY(-50%);
  }

  ${({ theme }) => theme.media.sm.min} {
    :not(:last-child) {
      left: -15%;
    }

    :last-child {
      right: -15%;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    :not(:last-child) {
      left: 1.5%;
    }

    :last-child {
      right: 1.5%;
    }
  }

  ${({ isHomepage }) =>
    isHomepage &&
    css`
      ${({ theme }) => theme.media.xxl.min} {
        :not(:last-child) {
          left: -7.5%;
        }

        :last-child {
          right: -7.5%;
        }
      }
    `}

  ${({ isHomepage }) =>
    !isHomepage &&
    css`
      ${({ theme }) => theme.media.xxxl.min} {
        :not(:last-child) {
          left: -4%;
        }

        :last-child {
          right: -4%;
        }
      }
    `}
`

const Slider: React.FC<Props> = ({ slides, slidesPerView }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const { xxl } = useBreakpoint()

  const isHomepage = useLocation() === '/'

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <SliderWrapper>
      <Swiper
        slidesPerView={slidesPerView}
        onSwiper={(initSwiper) => setSwiper(initSwiper)}
        spaceBetween={xxl ? 25 : 15}
        speed={1000}
        loop
      >
        {slides.map(
          (
            { src, alt, slideInnerLabel, label, link, additionalLabel },
            index
          ) => (
            <SwiperSlide key={alt + index}>
              <SlideWrapper>
                {isHomepage && <a href={link} />}
                <Zoom zoomMargin={xxl ? 80 : 30}>
                  <LazyImage src={src} alt={alt} objectFit="contain" />
                </Zoom>
                <SlideInnerLabel
                  size={30}
                  weight={700}
                  themecolor="white"
                  dangerouslySetInnerHTML={{ __html: slideInnerLabel }}
                />
              </SlideWrapper>
              <SlideInfo>
                <Text
                  size={14}
                  weight={500}
                  themecolor="primary"
                  margin="10px"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
                {additionalLabel && (
                  <Text
                    size={16}
                    weight={500}
                    themecolor="danger100"
                    transform="uppercase"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: additionalLabel }}
                  />
                )}
              </SlideInfo>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <ControlButton onClick={goPrev} isHomepage={isHomepage}>
        <Icon src={arrowIcon} width={18} alt="arrow-btn" />
      </ControlButton>
      <ControlButton onClick={goNext} isHomepage={isHomepage}>
        <Icon src={arrowIcon} width={18} alt="arrow-btn" />
      </ControlButton>
    </SliderWrapper>
  )
}

export default Slider
