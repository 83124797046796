import styled from 'styled-components'

const ScrollAnchor = styled.span`
  position: absolute;
  top: -110px;
  left: 0;

  ${({ theme }) => theme.media.lg.min} {
    top: -140px;
  }
`

export default ScrollAnchor
