import React from 'react'
import styled from 'styled-components'

import Icon from 'components/shared/icon'

import kvWingsIcon from 'assets/icons/wings-kv.svg'

import useBreakpoint from 'hooks/useBreakpoint'

const Wrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 900px;
  height: 100%;
  pointer-events: none;

  @media (min-width: 1280px) {
    display: block;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    width: 1160px;
  }
`

const KvWrapper = styled.div<{ top: string }>`
  position: absolute;
  top: ${({ top }) => top};
  z-index: 2;

  :nth-child(odd) {
    left: 0;
    transform: translateX(-107.5%);
  }

  :nth-child(even) {
    right: 0;
    transform: translateX(107.5%);
  }
`

const KVs = () => {
  const { xxl } = useBreakpoint()

  return (
    <Wrapper>
      <KvWrapper top={xxl ? '2.5%' : '2.25%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '11.25%' : '11%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '17.5%' : '17%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top="23.5%">
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '28.25%' : '29%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '36.25%' : '38%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '42%' : '44%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '49%' : '50.5%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '55%' : '57%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '66.25%' : '65.75%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top="73%">
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '81%' : '82%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
      <KvWrapper top={xxl ? '86.85%' : '86.25%'}>
        <Icon src={kvWingsIcon} size={xxl ? 175 : 150} alt="wings-kv" />
      </KvWrapper>
    </Wrapper>
  )
}

export default KVs
