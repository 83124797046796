import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'

import type { Image } from 'types/image'

import useBreakpoint from 'hooks/useBreakpoint'

type Slide = Image & { link: string; headingVisible: boolean }

type Props = {
  slides: Slide[]
  heading: string
  subheading: string
}

const StyledHeader = styled.header`
  margin-top: ${({ theme }) => theme.navSize.mobile};

  ${({ theme }) => theme.media.lg.min} {
    margin-top: ${({ theme }) => theme.navSize.desktop};
  }
`

const SwiperWrapper = styled.div`
  position: relative;

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
  }

  .swiper-slide > div {
    height: 100%;
  }

  ${({ theme }) => theme.media.xsm.min} {
    height: unset;
  }
`

const TextHeader = styled.div`
  width: 100%;
  padding: 5rem 0;
`

const StyledHeading = styled(Heading)<{ hidden?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: 500ms ease-in-out;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
  }

  ${({ theme }) => theme.media.xxl.min} {
    top: 7rem;
  }
`
const HeadingText = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: center;

  :first-of-type {
    font-family: Bebas Neue;
  }

  :last-of-type {
    letter-spacing: 6px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    :last-of-type {
      letter-spacing: 8px;
    }
  }
`

const IndicatorButtons = styled.div`
  position: absolute;
  left: 50%;
  bottom: 3rem;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 5;
`

const Button = styled.button<{ active: boolean; disabled: boolean }>`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  background-color: transparent;

  :not(:last-child) {
    margin-right: 1.75rem;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`

const Header: React.FC<Props> = ({ slides, heading, subheading }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const { lg, xxl } = useBreakpoint()

  return (
    <StyledHeader>
      <SwiperWrapper>
        <Swiper
          modules={[Autoplay]}
          loop
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          onSwiper={(initSwiper) => setSwiper(initSwiper)}
          onSlideChange={(swiperInstance) =>
            setActiveSlideIndex(swiperInstance.realIndex)
          }
          preventInteractionOnTransition
          onTransitionStart={() => setIsAnimating(true)}
          onTransitionEnd={() => setIsAnimating(false)}
          speed={1000}
        >
          {slides.map(({ src, alt, link, headingVisible }, index) => (
            <SwiperSlide key={alt + index}>
              <a href={link} target="_blank">
                <LazyImage src={src} alt={alt} />
              </a>
              {lg && (
                <StyledHeading hidden={!headingVisible} as="h1">
                  <HeadingText
                    size={xxl ? 80 : 64}
                    weight={500}
                    line={1}
                    themecolor="primary"
                    margin="0"
                    transform="uppercase"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <HeadingText
                    size={xxl ? 16 : 13}
                    weight={400}
                    line={1.5}
                    themecolor="primary"
                    margin="0"
                    transform="uppercase"
                    dangerouslySetInnerHTML={{ __html: subheading }}
                  />
                </StyledHeading>
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {lg && (
          <IndicatorButtons>
            {slides.map((item, index) => (
              <Button
                type="button"
                key={'button' + index}
                active={activeSlideIndex === index}
                onClick={() => {
                  swiper?.slideTo(index + 1)
                  setActiveSlideIndex(index)
                }}
                disabled={isAnimating}
              />
            ))}
          </IndicatorButtons>
        )}
      </SwiperWrapper>
      {!lg && (
        <TextHeader>
          <StyledHeading as="h1" margin="0">
            <HeadingText
              size={84}
              weight={500}
              line={1}
              themecolor="primary"
              margin="10px"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <HeadingText
              size={12}
              weight={400}
              line={1.4}
              themecolor="primary"
              margin="0"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: subheading }}
            />
          </StyledHeading>
        </TextHeader>
      )}
    </StyledHeader>
  )
}

export default Header
