import React, { lazy, Suspense, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'
import Navigation from 'components/layout/Navigation'
import Header from 'components/layout/Header'
import Promotions from 'components/layout/Promotions'
import KVs from 'components/layout/KVs'

const Plan = lazy(() => import('components/layout/Plan'))
const Shops = lazy(() => import('components/layout/Shops'))
const OpeningHours = lazy(() => import('components/layout/OpeningHours'))
const Location = lazy(() => import('components/layout/Location'))
const About = lazy(() => import('components/layout/About'))
const Newsletter = lazy(() => import('components/layout/Newsletter'))
const Footer = lazy(() => import('components/layout/Footer'))

import useBreakpoint from 'hooks/useBreakpoint'

const promotionsTiles = [
  {
    label: 'promocje',
    link: '#promotions',
    icon: 'pink',
    tileColor: '#df3393',
    iconBackgroundMobile: '#eb7fbb',
    iconBackgroundDesktop: '#d70078',
  },
  {
    label: 'sklepy',
    link: '#shops',
    icon: 'green',
    tileColor: '#a3d363',
    iconBackgroundMobile: '#c5e39d',
    iconBackgroundDesktop: '#8cc83c',
  },

  {
    label: 'plan parku handlowego',
    link: '#plan',
    icon: 'blue',
    tileColor: '#334b63',
    iconBackgroundMobile: '#7f8e9d',
    iconBackgroundDesktop: '#001e3c',
  },
]

const Main = styled.main`
  position: relative;
`

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  const HOMEPAGE = data?.wpPage?.stronaGlowna
  const PAGE_SEO = data?.wpPage?.seo

  const { lg } = useBreakpoint()

  if (!HOMEPAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HERO_SLIDES =
    useMemo(
      () =>
        HOMEPAGE?.heroSlider?.map((sliderItem) => ({
          src: sliderItem?.homepageSliderImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: sliderItem?.homepageSliderImg?.altText!,
          link: sliderItem?.homepageSliderLink!,
          headingVisible: sliderItem?.homepageSliderLinkWitamyWParku,
        })),
      []
    ) ?? []

  const PROMOTIONS_SLIDES =
    HOMEPAGE?.promotionsPromotion?.map((promotionItem) => ({
      src: promotionItem?.promotionsPromotionImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: promotionItem?.promotionsPromotionImg?.altText!,
      slideInnerLabel: '',
      label: promotionItem?.promotionsPromotionShopName!,
      link: promotionItem?.promotionsPromotionLink!,
      additionalLabel: promotionItem?.promotionsPromotionCommunication!,
    })) ?? []

  const SHOPS =
    HOMEPAGE?.shops?.map((shopItem) => ({
      pnPt: shopItem?.shopsMondayFriday!,
      sb: shopItem?.shopsSaturday!,
      nd: shopItem?.shopsSunday!,
      logo: {
        src: shopItem?.shopLogo?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: shopItem?.shopLogo?.altText!,
      },
      info: shopItem?.shopsCommunicate!,
    })) ?? []

  const OPENING_DAYS = [
    {
      day: 'pn. - sb.',
      hours: HOMEPAGE?.openingHoursMondaySaturday!,
    },
    {
      day: 'niedziela handlowa',
      hours: HOMEPAGE?.openingHoursSunday!,
    },
  ]

  const ABOUT_IMAGES =
    HOMEPAGE?.aboutUsGallery?.map((promotionItem) => ({
      src: promotionItem?.aboutUsGalleryImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: promotionItem?.aboutUsGalleryImg?.altText!,
    })) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Header
        slides={HERO_SLIDES}
        heading="witamy"
        subheading="w parku handlowym"
      />
      <Main>
        <Promotions
          heading="Promocje"
          tiles={promotionsTiles}
          slides={PROMOTIONS_SLIDES}
          buttonLabel="sprawdź wszystkie promocje"
        />
        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <Plan
              heading="Plan parku handlowego"
              img={{
                src: HOMEPAGE?.planImg?.localFile?.childImageSharp
                  ?.gatsbyImageData!,
                alt: HOMEPAGE?.planImg?.altText!,
              }}
            />
          </Suspense>
        </LazyLoad>
        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <Shops heading="Sklepy" shops={SHOPS} />
          </Suspense>
        </LazyLoad>

        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <OpeningHours
              heading="Godziny otwarcia"
              description={HOMEPAGE?.openingHoursDescription!}
              openingDays={OPENING_DAYS}
            />
          </Suspense>
        </LazyLoad>

        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <Location
              heading="Lokalizacja"
              location={HOMEPAGE?.mapAdress!}
              pin={{
                lat: HOMEPAGE?.mapLat!,
                lng: HOMEPAGE?.mapLng!,
              }}
            />
          </Suspense>
        </LazyLoad>

        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <About
              heading="O nas"
              description={HOMEPAGE?.aboutUsDescription!}
              images={ABOUT_IMAGES}
              description2={HOMEPAGE?.aboutUsShortDescription!}
              videoUrl={HOMEPAGE?.aboutUsVideo!}
            />
          </Suspense>
        </LazyLoad>

        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <Newsletter
              imgMobile={
                HOMEPAGE?.newsletterImgMobile?.localFile?.childImageSharp
                  ?.gatsbyImageData!
              }
              img={
                HOMEPAGE?.newsletterImg?.localFile?.childImageSharp
                  ?.gatsbyImageData!
              }
              heading="Newsletter"
            />
          </Suspense>
        </LazyLoad>

        <LazyLoad once offset={!lg ? 150 : 300}>
          <Suspense fallback={<></>}>
            <KVs />
          </Suspense>
        </LazyLoad>
      </Main>

      <LazyLoad once offset={!lg ? 150 : 300}>
        <Suspense fallback={<></>}>
          <Footer
            heading="Kontakt"
            salesOffice={{
              openingHours:
                HOMEPAGE?.contactSalesOffice?.contactSalesOfficeOpeningHours!,
              phone:
                HOMEPAGE?.contactSalesOffice?.contactSalesOfficePhoneNumber!,
              email: HOMEPAGE?.contactSalesOffice?.contactSalesOfficeEMail!,
            }}
          />
        </Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      stronaGlowna {
        heroSlider {
          homepageSliderLink
          homepageSliderLinkWitamyWParku
          homepageSliderImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
        promotionsPromotion {
          promotionsPromotionShopName
          promotionsPromotionCommunication
          promotionsPromotionLink
          promotionsPromotionImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 380, placeholder: BLURRED)
              }
            }
          }
        }
        planImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        shops {
          shopLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          shopsMondayFriday
          shopsSaturday
          shopsSunday
          shopsCommunicate
        }
        openingHoursDescription
        openingHoursMondaySaturday
        openingHoursSunday
        mapAdress
        mapLat
        mapLng
        aboutUsDescription
        aboutUsGallery {
          aboutUsGalleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        aboutUsShortDescription
        aboutUsVideo
        contactSalesOffice {
          contactSalesOfficeEMail
          contactSalesOfficeOpeningHours
          contactSalesOfficePhoneNumber
        }
        newsletterImgMobile: newsletterImgMobile {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 992
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        newsletterImg: newsletterImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
