import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Slider from 'components/layout/Promotions/Slider'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import wingsGreenIcon from 'assets/icons/wings-green.svg'
import wingsPinkIcon from 'assets/icons/wings-pink.svg'
import wingsBlueIcon from 'assets/icons/wings-blue.svg'

import wingsGreenCroppedIcon from 'assets/icons/wings-green-cropped.png'
import wingsPinkCroppedIcon from 'assets/icons/wings-pink-cropped.png'
import wingsBlueCroppedIcon from 'assets/icons/wings-blue-cropped.png'

import useBreakpoint from 'hooks/useBreakpoint'

import type { Slide } from 'components/layout/Promotions/Slider'

import { normalizeHeading } from 'utils/normalizeHeading'
import scrollToSection from 'utils/scrollToSection'

const getIcon = (icon: string, cropped: boolean) => {
  switch (icon) {
    case 'green':
      return cropped ? wingsGreenCroppedIcon : wingsGreenIcon
    case 'pink':
      return cropped ? wingsPinkCroppedIcon : wingsPinkIcon
    case 'blue':
      return cropped ? wingsBlueCroppedIcon : wingsBlueIcon
    default:
      return ''
  }
}

type Tile = {
  icon: string
  link: string
  iconBackgroundMobile: string
  iconBackgroundDesktop: string
  label: string
  tileColor: string
}

type Props = {
  heading: string
  tiles: Tile[]
  slides: Slide[]
  buttonLabel: string
}

const Section = styled.section`
  position: relative;
  width: 100%;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
  }
`

const TilesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  z-index: 2;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.25rem;
  }
`

const TileSingleWrapper = styled.button<{ tileColor: string }>`
  display: flex;
  align-items: center;
  height: 68px;
  margin-bottom: 1rem;
  background-color: ${({ tileColor }) => tileColor};

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    margin-bottom: 0;

    :first-child {
      order: 3;
    }

    :not(:first-child) {
      margin-right: 2rem;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    :not(:first-child) {
      margin-right: 3rem;
    }
  }
`

const TileInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    ${Text} {
      font-size: 1.25rem;
    }
  }

  ${({ theme }) => theme.media.xxxl.min} {
    ${Text} {
      font-size: 1.55rem;
    }
  }
`

const TileEndWrapper = styled.div<{
  backgroundMobile: string
  backgroundDesktop: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  min-width: 68px;
  height: 100%;
  background-color: ${({ backgroundMobile }) => backgroundMobile};

  ${({ theme }) => theme.media.lg.min} {
    position: relative;
    width: 63px;
    min-width: 63px;
    background-color: ${({ backgroundDesktop }) => backgroundDesktop};

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translate(-50%, -122.5%);
      width: 38px;
      height: 19px;
      background-color: transparent;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      border: 5px solid ${({ backgroundDesktop }) => backgroundDesktop};
      border-bottom: 0;
    }
  }
`

const SliderHeader = styled.div`
  width: 100%;
  padding: 4.25rem 0 3.5rem 0;

  ${({ theme }) => theme.media.xxl.min} {
    padding: 6rem 0 5rem 0;
  }
`

const StyledHeading = styled(Heading)`
  letter-spacing: 5px;
`

const IconWrapper = styled.div`
  width: 90%;
  height: 90%;

  div {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    height: 100%;
  }
`

const CheckAllPromosButton = styled.button`
  position: relative;
  width: 80%;
  max-width: 366px;
  height: 50px;
  margin: 2rem auto 3.5rem;
  font-size: 1.25rem;
  background-color: ${({ theme }) => theme.colors.danger100};
  border: 2px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  z-index: 2;

  transition: 200ms ease-in-out;

  ${({ theme }) => theme.media.s.min} {
    font-size: 1.45rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.danger100};
    border-color: ${({ theme }) => theme.colors.danger100};
  }
`

const Promotions: React.FC<Props> = ({
  heading,
  buttonLabel,
  tiles,
  slides,
}) => {
  const { s, lg } = useBreakpoint()

  return (
    <Section title={normalizeHeading(heading)}>
      <ScrollAnchor id="promotions" />
      <StyledContainer>
        <TilesWrapper>
          {tiles.map(
            ({
              icon,
              link,
              label,
              tileColor,
              iconBackgroundMobile,
              iconBackgroundDesktop,
            }) => (
              <TileSingleWrapper
                key={tileColor}
                tileColor={tileColor}
                onClick={() => scrollToSection(link)}
              >
                <TileInnerWrapper>
                  <Text
                    size={s ? 16 : 14}
                    weight={600}
                    themecolor="white"
                    transform="uppercase"
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                </TileInnerWrapper>

                <TileEndWrapper
                  backgroundMobile={iconBackgroundMobile}
                  backgroundDesktop={iconBackgroundDesktop}
                >
                  <IconWrapper>
                    <Icon
                      src={getIcon(icon, lg ? true : false)}
                      auto
                      alt="wings"
                    />
                  </IconWrapper>
                </TileEndWrapper>
              </TileSingleWrapper>
            )
          )}
        </TilesWrapper>
        <SliderHeader>
          <StyledHeading
            as="h2"
            size={lg ? 22 : 28}
            weight={500}
            themecolor="primary"
            margin="0"
            align="center"
            transform="uppercase"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </SliderHeader>
        <Slider slides={slides} slidesPerView={lg ? 3 : 1} />
        <CheckAllPromosButton onClick={() => navigate('/promocje')}>
          {buttonLabel}
        </CheckAllPromosButton>
      </StyledContainer>
    </Section>
  )
}

export default Promotions
